@import "src/scss/abstracts/variables";
@import "src/scss/abstracts/mixins";

/* styles for capital programs page only */
.capital-programs-wrap {
	padding: toRem(90);
	padding-top: 0;

	@include for-size(desktop-down) {
		padding: toRem(60);
		padding-top: 0;
	}
	@include for-size(tablet-down) {
		padding: toRem(30);
		padding-top: 0;
	}
}


/* styles for capital programs standard view */
.capital-programs {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: toRem(25);
	row-gap: toRem(25);

    .capital-program {
        width: calc(50% - 12.5px);
        height: auto;

        @include for-size(phone-down) {
            width: 100%;
        }
    }
}

.capital-program-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: toRem(30);
    border: 1px solid $color-gray-light;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
    text-decoration: none;
    transition: all 500ms ease;

    &:hover {
        text-decoration: none;
        color: $color-black;
        border: 1px solid $color-primary;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);

        &[href] {
            .capital-program__content-title {
                text-decoration: underline;
                color: $color-link-hover;
            }
        }
    }

    &__image {
        padding-top: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__content {
        height: 100%;
        padding: toRem(40);
        background: white;

        @include for-size(tablet-landscape-down) {
            padding: toRem(30);
        }

        &--list {
            padding: toRem(20) toRem(40) toRem(30) toRem(40);

            @include for-size(tablet-landscape-down) {
                padding: toRem(20) toRem(30) toRem(30) toRem(30);
            }

            .capital-program-item__content-divider {
                margin-top: toRem(8);
                margin-bottom: toRem(13);
            }
        }

        &-title {
            font-size: toRem(24);
            font-weight: bold;

            @include for-size(tablet-landscape-down) {
                font-size: toRem(22);
            }
            @include for-size(phone-down) {
                line-height: 1.25;
            }
        }

        &-subtitle {
            font-size: toRem(20);
            color: $color-gray-darker;

            @include for-size(tablet-landscape-down) {
                font-size: toRem(18);
            }
        }

        &-divider {
            width: toRem(40);
            height: toRem(3);
            background: $color-primary;
            margin-top: toRem(15);
            margin-bottom: toRem(20);
            border-radius: toRem(1.5);
        }

        &-text {
            font-size: toRem(18);
            font-weight: normal;
            line-height: 1.4;
        }
    }
}
